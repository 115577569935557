.product-info-main .pdp-wrapper2 .product-info-wrapper.saleable .subwrapper.product-info-subwrapper2 .store-pickup-wrapper button.action.primary.check-stock {
    background-color: #fff;
    color: #1d1160;
    border: solid 2px #1d1160;
}
li.menu-item {
    background-color: #ee3123!important;
}
.usp-wrapper {
    margin: 60px 0 30px 0;
}
.euroshop-icon-euro:before {
    opacity: 0.8;
}
.euroshop-icon-tumbsup:before{
    opacity: 0.8;
}
.euroshop-icon-box:before{
    opacity: 0.8;
}
.page-main .block {
    margin-bottom: 10px;
}
.trippel-home-block .trippel-home-block-item {
    width: 100%;
}
.header.content>.logo:before{
    display:none;
}
.brand-image-wrapper img {
    height: 5vh!important;
}
/*change footer title font size*/
.euroshop-footer .euroshop-footer-block-container-big h3 {
    font-size: 16px;
    line-height: 30px;
}
.euroshop-footer .euroshop-footer-block-container h3 {
    font-size: 16px;
    line-height: 30px;
}
.fotorama__stage__frame .fotorama__img {
        padding: 0 20px 0 20px;
}
.fotorama__thumb {
    background-color: #fff;
}
/*use alternate copyright*/
.copyright{
display:none
}
.copyright-es span {
    opacity: .8
}
.copyright-es a {
    color: #575757;
}
.copyright-es {
    background: #fff;
    color: #575757;
    font-size: 14px;
    font-weight: 100;
    line-height: 24px;
    border: none;
}
.copyright-es {
    box-sizing: border-box;
    display: block;
    padding: 10px;
    text-align: center;
}
.page-footer .footer.content {
    padding-bottom: 40px;
}
.footer.content {
    border-top: none;
}
@media screen and (min-width: 768px){
.copyright-es {
    padding: 15px 0 15px;
}
.breadcrumbs .items .item:not(:last-child):after {
    line-height: 28px;
}    
}
/*style category*/
 .subcategory-description {
    padding: 20px 20px 0 0!important;
}
.subcategory-items {
    padding: 30px 0 0 0;
    text-align:center;
}
.subcategory-item {
    height: 60px!important;
}
@media screen and (min-width: 768px){
 .subcategory-description {
    padding: 20px 0 0 0;
}
}
/*cookie*/
.message.global.noscript, .message.global.cookie {
    background:#333;
    opacity:0.95;
}
.message.global.cookie .actions {
    text-align: center;
}
.message.global p {
    text-align: center;
}
/*extra menu item CTA*/
.menu-main-wrapper h3::after {
    font-family: 'icomoon' !important;
    padding-left:5px;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    content: "\e900";
    display: inline-block;
    vertical-align: middle;
}
.menu-main-wrapper h3 {
    font-size: 20px;
    font-family: ProximaNova-Regular;
    margin-top:8px;
    color:#ffef2a;
}
li.push-right {
    background: #ee3123;
    color: #fff;
}   
/*recommend component*/
.recommend-component {
    display: none!important;
}
/* upgrade layout*/
/*pdp*/
@media (min-width: 769px), print{
.page-layout-1column .product-info-main2 .pdp-wrapper3 .info-detailed-title {
    color: #03031a;
    font-size: 26px;
    line-height: 30px;
}
.page-layout-1column .product-info-main2 .pdp-wrapper3 .product.info.detailed .product.data.items>.item.title>.switch {
    color: #03031a;
    font-size: 22px;
    line-height: 30px;
}
.page-layout-1column .product-info-main2 .pdp-wrapper4 .additional-attributes-wrapper .data.switch {
    font-family: ProximaNova-Bold;
    color: #03031a;
    font-size: 22px;
    line-height: 30px;
}
.catalog-product-view .block-title {
    color: #03031a;
    font-size: 26px;
    line-height: 30px;
}
.page-layout-1column .product-info-main2 .pdp-wrapper3 .product.info.detailed {
    margin: 25px 0 0;
}
.block.related {
    border-top: 1px solid #e6e6e8;
}
.product-info-main2 {
    border-top: 1px solid #e6e6e8;
}
.product.attribute.description ul {
    margin-left: 10px;
}
}
/*footer*/
.footer-background {
    height: 400%;
    position: absolute;
    opacity: .05;
    transform: rotate(-0deg) translate(-200px,-420px);
    z-index: -3;
    }
.page-footer {
    position: relative;
    overflow: hidden;
}
.euroshop-footer-block-container {
    z-index: 1;
}
.block.newsletter .form.subscribe {
    padding-top: 5px;
}
input#newsletter {
    border-radius: 5px 0 0 5px!important;
}
.euroshop-footer .euroshop-footer-block-container .block.newsletter .actions .action.primary, .euroshop-footer .euroshop-footer-block-container-big .block.newsletter .actions .action.primary {
    border-radius: 0 5px 5px 0!important;
}
@media only screen and (max-width: 767px){
    .footer-background {
        display:none
    }
}
/*remove search iconbox on mobile*/
.page-header .header.content .euroshop-mobile-tile-language {
margin-right: 10px
}
.euroshop-mobile-tile-search.euroshop-icon-search.euroshop-button-tile-mobile {
    display: none!important;
}
/*hrefs color*/
a:visited, .alink:visited {
    text-decoration: none;
}
a, .alink {
    color: #ee3123;
    text-decoration: none;
}
a:hover, .alink:hover {
    color: #ee3123;
    text-decoration: underline;
}
.menu-main a:hover {
    text-decoration: none;
    color: #ffffff;
}
.menu-main a:visited {
    color: #ffffff;
}
/*modern blog*/
.blog-post-view .page-title-wrapper {
    display: block!important;
}
